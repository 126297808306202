(<template>
  <interactive-block :title="title"
                     class="sh-pricing">
    <div class="sh-pricing__interaction-area">
      <p v-if="showPriceTitle"
         class="sh-pricing__title">{{ priceTitle }}</p>
      <template v-if="userIsInterpreter && jobFeedback">
        <p v-if="reportedStartTime && reportedFinishTime"
           class="sh-pricing__report">{{ reportedTimeText }}</p>
      </template>
      <p v-if="showPriceDescription"
         class="sh-pricing__description">{{ priceDescription }}</p>
      <invoice-draft-info v-for="(invoice, index) in invoices"
                          :key="index"
                          :hide-total="hideTotal"
                          :title="invoice.title"
                          :invoice="invoice"
                          :travel-costs="travelCosts"
                          class="sh-pricing__prices-cont">
        <span v-if="showDraftNote"
              class="sh-pricing__overlay-note">{{ $gettext('Draft') }}</span>
      </invoice-draft-info>
      <slot></slot>
    </div>
  </interactive-block>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import helpers from '@/helpers';
  import InteractiveBlock from '@/components/assignment_components/one_assignment/interactive_info/shared_components/InteractiveBlock';
  import InvoiceDraftInfo from '@/components/assignment_components/one_assignment/interactive_info/shared_components/InvoiceDraftInfo';

  export default {
    components: {
      'invoice-draft-info': InvoiceDraftInfo,
      'interactive-block': InteractiveBlock
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      travelCosts: {
        type: Object,
        default() {
          return {};
        }
      },
      hideTotal: Boolean,
      showDraftNote: Boolean
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter'
      ]),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {},
        jobFeedback: ({storeJobObj}) => storeJobObj?.jobSupplierFeedback || ''
      }),
      ...mapState('AssignmentFlowStore', {
        previewData: ({previewData}) => previewData || []
      }),
      reportedStartTime() { return this.jobFeedback.reportedStartTime; },
      reportedFinishTime() { return this.jobFeedback.reportedFinishTime; },
      reportedTimeText() {
        const template = this.$gettext('Reported start time: %{startTime} and end time: %{finishTime}');
        return this.$gettextInterpolate(template, {startTime: this.dateFormat(this.reportedStartTime), finishTime: this.dateFormat(this.reportedFinishTime)});
      },
      showPriceTitle() {
        return !!(this.priceTitle && this.invoices?.length);
      },
      priceTitle() { return this.$gettext('Honorar breakdown'); },
      showPriceDescription() { return false; },
      priceDescription() { return ''; },
      invoices() {
        if (this.previewData) {
          return this.previewData.map((data = {}) => {
            const titleTemplate = this.$gettext('Assignment #%{jobId}');
            const jobId = helpers.getJobInfo.jobId(data);

            return {
              ...data.invoice,
              title: this.$gettextInterpolate(titleTemplate, {jobId})
            };
          });
        }
      },

    },
    methods: {
      dateFormat(dateTime) {
        if (dateTime) {
          const formatStr = 'YYY-MM-DD hh:mm';
          const dateMoment = this.$moment(dateTime, formatStr);
          return `${dateMoment.format('HH:mm')}`;
        }
      }
    }
  };
</script>

<style>
  .sh-pricing__title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
  }
</style>

<style scoped>
  .sh-pricing__prices-cont {
    margin-bottom: 30px;
  }

  .sh-pricing__report {
    margin-bottom: 10px;
  }

  .sh-pricing__description {
    margin-bottom: 10px;
  }

  .sh-pricing__overlay-note {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    font-weight: bold;
    font-size: 55px;
    text-transform: uppercase;
    opacity: 0.4;
    cursor: default;
    transform: translate(-50%, -50%);
  }
</style>
