(<template>
  <shared-pricing class="pt-apply">
    <div v-if="project.interpretationRequirement.sessionType == 'video'"
         class="video-platform__section">
      <p class="video-platform__text"
         v-html="completedVideoText"></p>
    </div>
    <apply-actions>
      <button slot="applyButton"
              :disabled="isDisabledApply"
              class="sk-btn sk-btn--default pt-apply__apply-btn"
              @click="handleApply">{{ $gettext('Apply') }}</button>
    </apply-actions>
    <sk-note v-if="project.isPolice"
             :text="policeNoteText"
             class="pt-apply__police-note" />
    <sk-note v-if="cancellationNoteText"
             :text="cancellationNoteText"
             class="pt-apply__cancel-policy" />
  </shared-pricing>
</template>)

<script>
  import ApplyPrototype from '@/prototypes/one_assignment_page/ApplyPrototype';
  import ApplyActions from '@/components/assignment_components/one_assignment/interactive_info/pt_actions/ApplyActions';
  import SharedPricing from '@/components/assignment_components/one_assignment/interactive_info/shared_components/SharedPricing';
  import {mapState, mapGetters} from 'vuex';

  export default {
    components: {
      'shared-pricing': SharedPricing,
      'apply-actions': ApplyActions
    },
    extends: ApplyPrototype,
    computed: {
      ...mapState('OneAssignmentStore', {
        project: ({storeJobObj}) => storeJobObj
      }),
      ...mapGetters('OneAssignmentStore', [
        'isSecretaryAssignment',
        'customerUseOwnVideoSystem'
      ]),
      videoPlatform() {
        return this.project.interpretationRequirement?.videoPlatform;
      },
      isDisabledApply() {
        return this.isSameSupplierSeriesJob && !this.seriesJobConfirmation;
      },
      videoplatformText() {
        return this.$gettext('<b>Videoplatform:</b> ') + (this.videoPlatform?.name || this.$gettext('own video solution'));
      },
      policeNoteText() {
        return this.$gettext('This assignment is remunerated in accordance with the salærforskrift.');
      },
      videoSystemText() {
        if (this.customerUseOwnVideoSystem) {
          const videoPlatformName = this.videoPlatform?.name || this.$gettext('own video solution');
          const supplierTemplate = this.$gettext('The customer will be using %{videoPlatformName} for this assignment.<br />Please only apply for this assignment, if you are familiar with %{videoPlatformName}.<br />');
          const demanderTemplate = this.$gettext('%{videoPlatformName} will be used for this assignment.');
          const finishedJobTemplate = this.$gettext('%{videoPlatformName} was used for this assignment.<br />');
          const template = ['needsFeedback', 'finished'].includes(this.jobStatus)
            ? finishedJobTemplate
            : this.userIsNotInterpreter
              ? demanderTemplate
              : supplierTemplate;
          const content = this.$gettextInterpolate(template, {
            videoPlatformName
          });
          const videoPlatformInstructions = this.videoPlatformInstructions && (this.userIsNotInterpreter || this.assignmentIsAccepted)
            ? `<br />${this.videoPlatformInstructions}`
            : '';
          const videoPlatformUrl = this.videoPlatformUrl && (this.userIsNotInterpreter || this.assignmentIsAccepted)
            ? `<a class='sk-link sk-link--default' href='${this.videoPlatformUrl}' target='_blank'>${this.videoPlatformUrl}</a>`
            : '';
          return `${content}${videoPlatformInstructions}${videoPlatformUrl}`;
        }
      },
      specificationsText() {
        return this.$gettext('<b>Specifications</b> for the video meeting will either come in the confirmation email or sent by the customer in a separate email.');
      },
      completedVideoText() {
        return `${this.videoplatformText} <br> ${this.videoSystemText} <br> ${this.specificationsText}`;
      }
    }
  };
</script>
<style scoped>
.pt-apply__cancel-policy {
  margin-top: 10px;
}
</style>

<style scoped>
  .video-platform__section {
    border-radius: 3px;
    background-color: #e2d2bb;
  }

  .pt-apply__police-note {
    margin-top: 10px;
  }

  .video-platform__text {
    padding: 8px;
  }
</style>
