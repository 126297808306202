(<template>
  <section :class="[{'interactive-block--collapsed': !mobileInteractionContentOpened && title},
                    `interactive-block--${jobStatus}`]"
           class="interactive-block">
    <div v-if="title"
         class="interactive-block__title-cont"
         @click="collapseBlock">
      <h2 class="interactive-block__title"
          v-html="title"></h2>
      <p v-if="!mobileInteractionContentOpened"
         class="interactive-block__details">{{ $gettext('View details') }}</p>
    </div>
    <div class="interactive-block__content">
      <sk-note v-if="showProactiveNote"
               :text="proactiveInfoMessage"
               class="post-assignment__note" />
      <sk-note v-if="showCancelNote"
               :text="cancelInfoMessage"
               class="post-assignment__note" />
      <slot></slot>
    </div>
  </section>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';

  export default {
    props: {
      title: {
        type: String,
        default: ''
      }
    },
    computed: {
      ...mapState('OneAssignmentStore', ['mobileInteractionContentOpened', 'storeJobObj']),
      jobStatus() { return this.storeJobObj ? this.storeJobObj.status : ''; },
      showProactiveNote() { return this.storeJobObj.status === 'proactive'; },
      cancelledReasonKey() { return this.storeJobObj?.cancelledReason || ''; },
      showCancelNote() {
        return this.storeJobObj.status === 'cancelled' && this.cancelledReasonKey !== 'direct_said_no';
      },
      cancelInfoMessage() {
        return this.$gettext('This assignment will be calculated according to cancellation policy, and will be paid out if all criteria are met.');
      },
      proactiveInfoMessage() {
        return this.$gettext('This assignment is made available to all our preferred interpreters, and can be claimed at any time. Please review the files and claim the assignment before you start working on this assignment.');
      }
    },
    methods: {
      ...mapMutations('OneAssignmentStore', ['openMobileInteractionContent']),
      collapseBlock() {
        if (!this.mobileInteractionContentOpened) {
          this.openMobileInteractionContent();
          setTimeout(() => {
            document.documentElement.scrollTop = 9999999999;
          }, 0);
        }
      }
    }
  };
</script>

<style scoped>
.post-assignment__note {
  margin-bottom: 15px;
}

.interactive-block {
  display: block;
  width: 100%;
  background-color: #fff;
}

.interactive-block__title-cont {
  padding: 10px 30px;
  background-color: #b2dadc;
  color: #008489;
}

.interactive-block__title {
  font-size: 18px;
}

.interactive-block__details {
  display: none;
}

.interactive-block__content {
  padding: 20px 30px;
}

@media (max-width: 767px) {
  .interactive-block--collapsed {
    position: fixed;
    bottom: 70px;
    z-index: 10;
    overflow: hidden;
    margin: 0 !important;
    border-radius: 15px 15px 0 0 !important;
  }

  .interactive-block--collapsed .interactive-block__content {
    display: none;
  }

  .interactive-block__details {
    display: block;
    margin-top: 4px;
    text-decoration: underline;
  }

  .interactive-block__content {
    padding: 20px 15px;
  }
}
</style>
