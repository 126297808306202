(<template>
  <div class="apply-actions">
    <sk-checkbox v-if="isSameSupplierSeriesJob"
                 :item-text="seriesJobConfirmationText"
                 :preselected-value="seriesJobConfirmation"
                 :validation-name="seriesJobConfirmationValidationName"
                 class="apply-actions__checkbox"
                 @checkboxvaluechanged="setSeriesJobConfirmation" />
    <div class="apply-actions__wrapper">
      <div class="apply-actions__button-wrapper">
        <div class="apply-actions__actions-cont">
          <slot name="applyButton"></slot>
          <button v-if="canAbandon"
                  class="sk-btn sk-btn--white apply-actions__decline-btn"
                  @click="handleAbandon">{{ $gettext('Decline') }}</button>
        </div>
        <a v-if="supportPortalLink"
           :href="supportPortalLink"
           class="sk-btn sk-btn--white apply-actions__btn"
           target="_blank">{{ $gettext('Frequently asked questions') }}</a>
      </div>
      <p v-if="helpArticleLink"
         class="apply-actions__help-link"
         v-html="helpArticleText"></p>
    </div>
  </div>
</template>)

<script>
  import ApplyPrototype from '@/prototypes/one_assignment_page/ApplyPrototype';

  export default {
    extends: ApplyPrototype
  };
</script>

<style>
  .apply-actions__highlighted-text {
    color: #ff5b24;
    font-weight: bold;
  }
</style>

<style scoped>
  .apply-actions {
    margin-top: 30px;
  }

  .apply-actions__wrapper {
    display: flex;
  }

  .apply-actions__checkbox {
    margin-bottom: 30px;
  }

  .apply-actions__button-wrapper {
    display: block;
    order: 0;
    width: 100%;
    max-width: 265px;
  }

  .apply-actions__actions-cont {
    display: flex;
    margin-bottom: 20px;
  }

  .apply-actions__decline-btn {
    flex-shrink: 0;
    width: auto;
    margin-left: 15px;
    padding: 0 15px;
  }

  .apply-actions__help-link {
    order: 1;
    margin-left: 40px;
    font-size: 12px;
  }

  @media (max-width: 767px) {
    .apply-actions__wrapper {
      flex-wrap: wrap;
    }

    .apply-actions__help-link {
      order: 0;
      margin-bottom: 20px;
      margin-left: 0;
      font-size: 12px;
    }

    .apply-actions__button-wrapper {
      order: 1;
      max-width: 100%;
    }
  }
</style>
